import { useState } from "react";
import Hamburger from "hamburger-react";
import { StyledMobileNav } from "./StyledMobileNav";

function MobileNav({ customClass }) {
  const [openMenu, setOpenMenu] = useState(false);

  const onClickHamburgerMenu = () => {
    setOpenMenu(!openMenu);
  };
  return (
    <StyledMobileNav className={customClass}>
      <div className="burger-icon">
        <Hamburger toggled={openMenu} toggle={onClickHamburgerMenu} />
      </div>
      {openMenu && (
        <div className="menu">
          <div className="nav-items">
            <a onClick={onClickHamburgerMenu} href="#about">
              About
            </a>
            <a onClick={onClickHamburgerMenu} href="#skills">
              Skills
            </a>
            <a onClick={onClickHamburgerMenu} href="#projects">
              Project
            </a>
            <a onClick={onClickHamburgerMenu} href="#contact">
              Contact
            </a>
            <a onClick={onClickHamburgerMenu} href="#resume">
              Resume
            </a>
          </div>
        </div>
      )}
    </StyledMobileNav>
  );
}

export default MobileNav;
