import styled from "styled-components";

export const StyledHero = styled.section`
  background-image: url(/images/magicpattern.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;

  .d-none {
    display: none;
  }

  .d-mob-none {
    display: block;
  }

  .hero-box-parent {
    width: max-content;
    min-height: 75%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .hero-box-general {
    color: #1c1b21;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    text-align: center;
    border-bottom: 8px solid #3434349e;
  }
  .hello {
    max-height: 123px;
    max-width: 22.6rem;
    background-color: #b9e4ff;
    font-size: 2.7rem;
    padding: 30px 10px;
    border-radius: 7rem;
    transform: rotate(-19deg);
    font-weight: 800;
    position: relative;
    z-index: 4;
  }
  .iam {
    max-height: 210px;
    max-width: 42.5rem;
    background-color: #f9d34c;
    font-size: 9.6rem;
    padding: 20px 100px;
    border-radius: 11rem;
    transform: rotate(-11deg);
    position: relative;
    left: 5rem;
    bottom: 3rem;
    z-index: 3;
  }
  .kavya {
    max-height: 216px;
    max-width: 46.5rem;
    background-color: #f2a4d3;
    font-size: 9.6rem;
    padding: 24px 80px;
    border-radius: 8rem;
    transform: rotate(10.53deg);
    position: relative;
    top: 5rem;
    right: 3rem;
  }

  .hero-smiley {
    position: relative;
    left: 7rem;
    width: 9rem;
    /* transform: rotate(-63deg); */
    img {
      animation: rotation 5s infinite linear;
    }
  }

  @keyframes rotation {
    15% {
      transform: rotate(20deg);
    }
    30% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(-50deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  @media (max-width: 1024px) {
    height: 80vh;
    .d-mob-none {
      display: none;
    }
    .d-none {
      display: block;
    }
    .hello {
      max-width: 20rem;
      font-size: 2.2rem;
      padding: 24px 2px;
    }

    .iam {
      max-width: 40rem;
      font-size: 8rem;
      padding: 16px 80px;
    }

    .kavya {
      max-width: 25rem;
      font-size: 8rem;
      padding: 22px 70px;
    }
  }
  @media (max-width: 426px) {
    height: 100vh;
    .hero-box-parent {
      min-height: 62%;
      width: auto;
      max-width: 100vw;
    }
    .kavya {
      top: 7rem;
      right: 10rem;
      font-size: 6rem;
      padding: 12px 40px;
      max-width: max-content;
    }
    .hero-smiley {
      left: 10rem;
      top: 8rem;
      img {
        width: 9rem;
      }
    }

    .hello {
      max-width: 16rem;
      padding: 14px 2px;
      font-size: 2rem;
      left: 10rem;
      bottom: 3rem;
    }

    .iam {
      max-width: max-content;
      font-size: 4rem;
      padding: 4px 35px;
      left: 10rem;
    }
  }
`;
