import styled from "styled-components";

export const StyledMobileNav = styled.section`
  position: fixed;
  width: 100vw;
  z-index: 11;

  .menu {
    height: 100vh;
    position: fixed;
    top: 0;
    width: 100%;
    margin: 0 auto;
    background-image: url(/images/magicpattern.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .burger-icon {
    display: flex;
    justify-content: end;
    color: #fdf6ee;
    position: relative;
    z-index: 111;
  }
  .nav-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6rem;
    /* margin-top: 12rem; */
    justify-content: center;
    height: 100vh;
    margin-top: -48px;

    a {
      color: #8ad2ff;
      font-size: 20px;
      font-family: "Work Sans", sans-serif;
      font-weight: 500;
      text-decoration: none;
      cursor: pointer;
    }
  }
  @media (max-width: 425px) {
    font-size: 16px;
  }
`;
