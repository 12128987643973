import styled from "styled-components";

export const StyledSectionHeading = styled.section`
  .heading-container {
    width: 200px;
    position: relative;
    transform: rotate(-6deg);
    margin: 0 auto;
    margin-bottom: 10rem;
  }

  .heading {
    background-color: #c6d794;
    width: 200px;
    height: 36px;
    border-radius: 20px;
  }

  .heading-text {
    color: #1c1b21;
    font-family: "Exo 2", sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 24px;
    text-align: center;
  }

  /* @media (max-width: 1024px) {
    
  } */
`;
